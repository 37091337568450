var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import { mapState } from "vuex";
import gql from "graphql-tag";
import globalCartFragment from "../../api/globalCartFragment.graphql";
import HeaderTemplate from "../ui/HeaderTemplate.vue";
import { signOut } from "../../utils/auth";
import auFlagImage from "../../images/au-flag.png";
import cnyFlagImage from "../../images/cny-flag.png";
import sgdFlagImage from "../../images/sgd-flag.png";
import idrFlagImage from "../../images/idr-flag.png";
var FLAGS = {
    AUD: {
        image: auFlagImage,
        alt: "Australian Flag",
    },
    CNY: {
        image: cnyFlagImage,
        alt: "Chinese Flag",
    },
    SGD: {
        image: sgdFlagImage,
        alt: "Singaporean flag",
    },
    IDR: {
        image: idrFlagImage,
        alt: "Indonesian flag",
    },
};
export default Vue.extend({
    components: {
        HeaderTemplate: HeaderTemplate,
    },
    apollo: {
        cart: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query PublicHeaderQuery($id: ID!) {\n          cart(id: $id) {\n            id\n            items {\n              id\n            }\n            ...globalCart\n          }\n        }\n        ", "\n      "], ["\n        query PublicHeaderQuery($id: ID!) {\n          cart(id: $id) {\n            id\n            items {\n              id\n            }\n            ...globalCart\n          }\n        }\n        ", "\n      "])), globalCartFragment),
            variables: function () {
                return {
                    id: this.cartId,
                };
            },
            skip: function () {
                return !this.cartId;
            },
        },
        viewer: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      {\n        viewer {\n          id\n          type\n        }\n      }\n    "], ["\n      {\n        viewer {\n          id\n          type\n        }\n      }\n    "]))),
        viewerStudent: gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      {\n        viewerStudent {\n          id\n          fullName\n        }\n      }\n    "], ["\n      {\n        viewerStudent {\n          id\n          fullName\n        }\n      }\n    "]))),
    },
    computed: __assign(__assign({}, mapState(["locale", "currency", "currencies", "cartId"])), { 
        // This is a workaround. Currently cart query is skipped if there's no cart id.
        // This is a problem when cart id is removed, because query remains with old obj
        currentCart: function () {
            if (!this.cartId) {
                return;
            }
            return this.cart;
        }, currenciesWithFlag: function () {
            return this.currencies.map(function (currency) { return (__assign({ flag: FLAGS[currency.value] }, currency)); });
        } }),
    methods: {
        onSignOut: function () {
            signOut(this.$store, this.$router, this.$apollo);
        },
        onDashboardSelect: function (option) {
            if (option.value === "signOut") {
                this.onSignOut();
                return;
            }
            this.$router.push(option.value);
        },
        onCurrencyChange: function (currency) {
            document.location.href = "/".concat(this.locale, "/").concat(currency.toLowerCase()).concat(this.$route.path);
        },
    },
});
var templateObject_1, templateObject_2, templateObject_3;
